import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setCart, setSelectedOutlet, setSelectedState } from "../../store/slices/orderSlice";
import {toggleLogin, toggleLogout, toggleMobileCart} from "../../store/slices/appSlice";
import { logo_dark } from "../../utils/assets";
import LoginModal from "../Modals/LoginModal";
import { getInitials, makeTitleCase } from "../../utils/helpers";
import LogoutModal from "../Modals/LogoutModal";
import { Dropdown } from "react-bootstrap";
import ForgotPasswordModal from "../Modals/ForgotPasswordModal";

const Header = ({switchCart, isBreakfast}) => {
    const location = useLocation()
    let dispatch = useDispatch();
    let history = useNavigate();
    const { selectedOutlet } = useSelector(state=>state.order)
    const {outlets, homePaths, showLoginModal, showLogoutModal, showRPModal} = useSelector(state=>state.app)
    const {userInfo} = useSelector(state=>state.user)
    const [isScroll, setIsScroll] = useState(false)
    window.addEventListener('scroll', ()=>{
      let  scroll = window.scrollY;
      scroll < 200 ? setIsScroll(false) :  setIsScroll(true)
    })
    const {cart} = useSelector(state=>state.order)
    const selectOutlet = (e) => {
      e.preventDefault();
        dispatch(setSelectedOutlet(null))
        dispatch(setSelectedState(null))
        dispatch(setCart([]));
    }

    const handleShowLogin = (e) => {
      e.preventDefault();
      dispatch(toggleLogin());
    };

    const handleShowLogout = (e) => {
      e.preventDefault();
      dispatch(toggleLogout());
    };

    const outlet = outlets?.find(item=>selectedOutlet?item._id === selectedOutlet:null);

  return (<>
    <header className="header_area header_1 transparent_header">
      <div id="sticky-header" className={`header_wrap header_space ${isScroll?"sticky":""}`}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-2 col-6">
              <div className="logo">
                <Link to="/">
                  <img src={logo_dark} alt="logo" style={{width:"70%"}} />
                </Link>
              </div>
            </div>
            {!isBreakfast &&
            <div className="col-lg-10 col-6">
              <div className="main_menu_wrap navbar navbar-expand-lg">
                <nav className="main_menu collapse navbar-collapse">
                  <ul className="main_menu_list ul_li">
                  <li className={`${location.pathname === "/menu"?"active":""}`}>
                  <Link to="/menu/all" className="nav-link">
                        Menu
                      </Link>
                      <ul className="sub-menu">
                      <li> <Link to="/menu/rice">Rice</Link> </li>
                        <li> <Link to="/menu/national">National</Link> </li>
                        <li> <Link to="/menu/protein">Protein</Link> </li>
                        <li> <Link to="/menu/pizza">Pizza</Link> </li>
                        <li> <Link to="/menu/drinks">Drinks</Link> </li>
                        <li> <Link to="/menu/pastries">Pastries</Link> </li>
                        <li> <Link to="/menu/all">View All Menu</Link> </li>
                      </ul>
                    </li>
                    {/* <li className={`${location.pathname === "/"?"active":""}`}>
                      <Link className="nav-link" to="/">
                        Home
                      </Link>
                    </li> */}
                    {/* <li className={`${location.pathname === "/about"?"active":""}`}>
                      <Link className="nav-link" to="/about">
                        About
                      </Link>
                    </li>
                    */}
                    <li className={`${location.pathname === "/restaurants"?"active":""}`}>
                      <Link className="nav-link" to="/restaurants">
                        Our Restaurants
                      </Link>  </li>
                      {/* <li className={`${location.pathname === "/blog"?"active":""}`}>
                      <Link className="nav-link" to="/blog">
                        Blog
                      </Link> </li> */}
                      <li className={`${location.pathname === "/contact"?"active":""}`}>
                      <Link className="nav-link" to="/contact">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </nav>

                <div className="header_right ul_li_right">
                  {/* <div className="search_box">
                    <form action="#">
                      <input type="text" placeholder="Enter your search" />
                      <button type="submit">
                        <i className="ti-search" />
                      </button>
                    </form>
                  </div> */}


                  <div className="header_carts ul_li">
                    <div className="hamburger_menu" style={{display:"flex"}}>
                    
                        <div  style={{cursor:"pointer"}} className="icon cart_btn d-lg-block">
                        <Dropdown>
                          <Dropdown.Toggle as={CustomUserToggle} variant="success" id="dropdown-basic">
                          <i className="fal fa-user" style={{color:"#1e1d23"}} />
                          {userInfo && <small onClick={()=>history("/user/orders")} className="cart_counter" style={{backgroundColor:"#018401"}}>{getInitials(userInfo?.name)}</small>}
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                           {userInfo && <> <p style={{textAlign:"center", color:"#000", fontWeight:500}}>{makeTitleCase(userInfo?.name)}</p>
                            <Dropdown.Divider /></>}
                            {userInfo && <>
                            <Dropdown.Item href="/user/orders">Orders</Dropdown.Item>
                            <Dropdown.Item href="/user/profile">My Account</Dropdown.Item>
                            <Dropdown.Item href="/user/addresses">Address Book</Dropdown.Item>
                            <Dropdown.Divider /></>}
                            <Dropdown.Item onClick={userInfo?handleShowLogout:handleShowLogin}>{userInfo?"Logout":"Login"}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        </div>

                        <div  style={{cursor:"pointer"}}  className="icon cart_btn d-lg-block" onClick={()=>{switchCart();dispatch(toggleMobileCart())}}>
                          <i className="fal fa-shopping-basket" />
                         {cart.length > 0 && <small className="cart_counter">{cart.length}</small>}
                        </div>

                        {!homePaths.includes(location.pathname) ?
                   <a className="icon mobile_only" href="#" onClick={selectOutlet} style={{}}>
                    {selectedOutlet && <small className="cart_counter" style={{backgroundColor:"darkred"}}>{getInitials(outlet?.name)}</small>}
                      <i className="fal fa-map-marker-alt" />
                    </a>
                    : <div style={{cursor:"pointer"}} className="icon bar d-lg-none" onClick={switchCart}>
                    <span>
                      <i className="fal fa-bars" />
                    </span>
                  </div>
                    
                    }

                       
                      {/* </a> */}
                    </div>

                  {!homePaths.includes(location.pathname) && 
                   <a className="icon cart_wishlist" href="#" onClick={selectOutlet} style={{width:"unset", borderRadius:30, padding:"0px 15px"}}>
                      <i className="fal fa-map-marker-alt" style={{marginRight:5}} />
                    <span style={{fontSize:12}}>{outlet?.name}</span>
                    </a>}

                  </div>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </header>
    {showLoginModal && <LoginModal />}
    {showRPModal && <ForgotPasswordModal />}
    {showLogoutModal && <LogoutModal />}
    </>
  );
};

export default Header;


const CustomUserToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    {/* &#x25bc; */}
  </a>
));